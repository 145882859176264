import { mapSelects, mapNested } from '@common/form'

export const FormLinks = {
  computed: {
    ...mapSelects([
      {
        name: 'contactLinkNetworkId',
        collection: {
          data: 'link_networks.data',
          key: 'id',
          value: 'attributes.name',
          options: {
            map: {
              link_network_id: 'id',
              color: 'attributes.color',
              icon: 'attributes.icon',
              validation: 'attributes.validation_browser',
            },
          },
        },
      },
    ]),
    ...mapNested([
      {
        name: 'linksAttributes',
        default: {
          data: 'relationships.links.data',
          key: 'id',
          type: 'link',
          attr: { _destroy: true },
        },
        attributes: 'attributes.links_attributes',
        many: true,
      },
    ]),
    contactLinkNetworkIdOptionsProcessed() {
      return this.contactLinkNetworkIdOptions.map((v) => {
        return { ...v, $isDisabled: this.contactLinkNetworksInUse.includes(v.value) }
      })
    },
    contactLinkNetworksInUse() {
      return this.$getDeep(this.form.data, 'attributes.links_attributes')
        .filter((v) => !v._destroy)
        .map((v) => v.link_network_id)
    },
  },

  methods: {
    addLink(value) {
      if (value) {
        /* Disable select option in link networks. */
        this.contactLinkNetworksSelected(value.meta).$isDisabled = true

        /* Re-enable link attributes or create new one. */
        let link = this.linksAttributes.find((v) => v.link_network_id === value.value)
        if (link) {
          this.$setDeep(link, '_destroy', false)
          /* Workaround to get validation re-processing. */
          if (link.id) return
          this.linksAttributes.push({ link_network_id: value.value, value: '' })
          this.linksAttributes.shift()
        } else {
          this.linksAttributes.push({
            link_network_id: value.value,
            value: '',
          })
        }
      }
    },
    removeLink(index) {
      const selected = this.contactLinkNetworksSelected(this.linksAttributes[index])

      /* Enable select option in link networks. */
      if (this.form.helpers.link_network && selected.value === this.form.helpers.link_network.value)
        this.form.helpers.link_network = null
      selected.$isDisabled = false

      /* Set link attributes to _destroy if already persisted else splice from links. */
      if (this.linksAttributes[index].id) {
        this.$setDeep(this.linksAttributes[index], '_destroy', true)
      } else {
        this.linksAttributes.splice(index, 1)
      }
    },
    contactLinkNetworksSelected(link) {
      return this.contactLinkNetworkIdOptionsProcessed.find((v) => v.value === link.link_network_id)
    },
    contactLinkFormInputPos(link_network_id) {
      if (this.contactLinkNetworksInUse.length <= 1) return ''

      const index = this.contactLinkNetworksInUse.indexOf(link_network_id)
      if (index === 0) return 'form-input-first'
      if (index + 1 === this.contactLinkNetworksInUse.length) {
        return 'form-input-last'
      } else {
        return 'form-input-middle'
      }
    },
    apiCallback() {
      /* Re-touch composite to have reactive object ready. */
      this.linksAttributes
    },
  },
}
