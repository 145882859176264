<template lang="pug">
  b-form-group.wc-forms-link-item(
    v-if='!item._destroy'
    :id='`form-contact-link_attributes-${index}-link_network`'
    :label='linkNetwork.label'
    :label-for='`contact-link_attributes-${index}-link_network_id`'
    :label-cols-md='labelColsMd'
    label-align-md='right'
    :label-sr-only='labelSrOnly')
    b-row.no-gutters
      b-col.mr-4
        b-input-group
          b-input-group-prepend
            b-input-group-text.bg-transparent(
              :class='inputCssClasses')
              i.fa-fw(
                :style='{ color: linkNetwork.meta.color }'
                :class='linkNetwork.meta.icon')
          b-form-input.no-group-append.is-required(
            :id='`contact-link_attributes-${index}-value`'
            :name='`links[${index}].value`'
            type='text'
            autocomplete='link'
            :placeholder="$t('contact/link.placeholders.value')"
            :state='formValidationState(validation.value)'
            :class='inputCssClasses'
            @focus.native='formChange'
            v-model='validation.value.$model'
            :aria-describedby='`contact-link_attributes-${index}-value-feedback`'
            trim)
          wc-forms-if.w-100(
            :id='`contact-link_attributes-${index}-value-feedback`'
            extra-class='ml-feedback'
            :attribute='validation.value'
            :remote="formRemoteInvalidFeedback(`links[${index}].value`)"
            :validators='{ required: {}, regExp: {}, maxLength: {} }')
      b-col.col-auto
        b-button.btn-circle.shadow(
          size='sm'
          :id='`contact-link_attributes-${index}-remove`'
          @click.stop.prevent='removeItem(index)'
          variant='primary')
          i.fal.fa-2x.fa-times.text-white
</template>

<script>
import { Form } from '@common/form'

export default {
  name: 'wc-forms-link-item',
  mixins: [Form],
  props: {
    index: Number,
    item: Object,
    validation: Object,
    formErrors: Object,
    formStatus: Object,
    formInputPos: String,
    linkNetwork: Object,
    labelSrOnly: {
      type: Boolean,
      default: false,
    },
    labelColsMd: {
      type: Number,
      default: 2,
    },
    shadow: {
      type: String,
      default: 'shadow-sm',
    },
  },
  computed: {
    inputCssClasses() {
      return [this.formInputPos, this.shadow].join(' ')
    },
  },
  methods: {
    removeItem(index) {
      this.$emit('remove-item', index)
    },
  },
  watch: {
    item(value) {
      this.form.data = value
    },
    formErrors(value) {
      this.form.errors = value
    },
    formStatus(value) {
      this.form.status = value
    },
    'item._destroy'() {
      this.validation.value.$reset()
    },
  },
  data() {
    return {
      skipFormBootstrap: true,
      skipFormFill: true,
      form: {
        data: this.item,
        errors: this.formErrors,
        status: this.formStatus,
      },
    }
  },
}
</script>
